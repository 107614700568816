var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokyo_tm_contact"},[_c('div',{staticClass:"fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact_form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"first"},[_c('ul',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Name","autocomplete":"off"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('li',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"Message"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('div',{staticClass:"tokyo_tm_button"},[_c('button',{staticClass:"white-fill-bg fill-black",attrs:{"type":"submit"}},[_vm._v(" Send Message ")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }