<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
        <div
          class="main"
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span v-scroll-reveal="{ delay: 20 }">About Me</span>
        <h3 v-scroll-reveal="{ delay: 40 }">
          Senior Full-Stack Developer
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          As a seasoned Full-Stack Developer, I specialize in crafting high-performance, accessible web solutions. I skillfully navigate both front-end and back-end landscapes to deliver seamless, user-focused applications. My technical proficiency encompasses a comprehensive toolkit, including React, Next.js, Angular, Node.js, TypeScript, MongoDB, PostgreSQL, and AWS, with a particular emphasis on intuitive UI/UX design. 
        </p>
      </div>
      <div class="shane_tm_button" v-scroll-reveal="{ delay: 80 }">
        <a :href="cv" download>
          Download CV
        </a>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/1.jpg"),
      cv: "/resume/Resume.pdf",
    };
  },
};
</script>
