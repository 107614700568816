<template>
  <div class="map_wrap">
    <iframe
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2854.9161635669325!2d-69.79193708374547!3d44.31167397910428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb206b2c1539fb3%3A0x27d58de6392139a5!2s2%20Hillcrest%20St%2C%20Augusta%2C%20ME%2004330!5e0!3m2!1sen!2sus!4v1633611122653!5m2!1sen!2sus"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
