<template>
  <div>
    <h3 class="mb-4">Employment History</h3>
    <div>
      <Timeline
        :timeline-items="dataTimeline"
        :message-when-no-items="messageWhenNoItems"
        :unique-year="true"
        :show-day-and-month="true"
        order="desc"
      />
    </div>
  </div>
</template>

<script>
import Timeline from "timeline-vuejs";
import "../../node_modules/timeline-vuejs/dist/timeline-vuejs.css";

const dotColor = "#5261E5";
export default {
  name: "History",
  components: { Timeline },
  data() {
    return {
      dotColor: "#5261E5",
      messageWhenNoItems: "There arent items",
      dataTimeline: [
        // {
        //   from: new Date(2016, 5, 22),
        //   title: "Name",
        //   showDayAndMonth: true,
        //   color: dotColor,
        //   description:
        //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.",
        // },
        {
          from: new Date(2011, 3),
          title: "Software Developer | DataGroup Technologies, Inc.",
          color: dotColor,
          description:
            "- Streamlined and optimized legacy codebases for enhanced performance and compatibility with current technological frameworks. <br>- Took on the role of Database Administrator, leading initiatives in website data analysis to inform strategic decisions. <br>- Developed and implemented utilities to streamline database operations, introducing efficient data management and intuitive visualization tools. This role allowed me to deepen my expertise in software development and database management, reinforcing my dedication to technological innovation and efficiency.",
        },
        {
          from: new Date(2014, 6),
          title: "Frontend Developer | Brightly",
          color: dotColor,
          description:
            "- Engineered responsive web applications from concept to deployment for a startup in the digital marketing space, utilizing HTML5, CSS3, and JavaScript with a focus on AngularJS for dynamic content delivery. <br>- Implemented SEO best practices and optimized page load times, resulting in a 50% increase in user engagement and a 40% increase in page views. <br>- Developed and maintained code for high-traffic websites, ensuring compatibility across browsers and devices. <br>- Collaborated with designers and backend developers to create a cohesive and integrated user experience.",
        },
        {
          from: new Date(2016, 6),
          title: "Senior Frontend Developer | Amazon Web Services",
          color: dotColor,
          description:
            "- Developed cutting-edge cloud-based applications for AWS, focusing on high scalability and reliability requirements of enterprise-level clients. <br>- Implemented responsive web designs with React and managed application state with Redux, enhancing user engagement across various devices. <br>- Collaborated with cross-functional teams to design and develop a suite of tools that improved developer productivity and operational efficiency. <br>- Advocated for and implemented best practices in code quality, testing, and deployment, contributing to a culture of excellence and continuous improvement.",
        },
        {
          from: new Date(2019, 9),
          title: "Full Stack Developer | Hy-Vee, Inc.",
          color: dotColor,
          description:
            "- Played a key role in developing and enhancing e-commerce platforms, focusing on creating intuitive, user-friendly interfaces with Angular and integrating them with secure, scalable backend services built with Node.js and MongoDB. <br>- Led the transition to a microservices architecture, improving modularity and enabling more agile development and deployment processes. <br>- esigned and implemented APIs for cross-platform use, facilitating seamless integration between web, mobile, and third-party applications. <br>- Contributed to the development of an in-house CMS using React and Node.js, significantly reducing the time required to launch new marketing campaigns.",
        },
        {
          from: new Date(2022, 3),
          title: "Senior Full Stack Developer | Stay AI",
          color: dotColor,
          description:
            "- Developed and maintained high-traffic AI-driven SaaS platforms for the E-Commerce industry, utilizing a full stack of React, Node.js, TypeScript, MongoDB, and PostgreSQL. <br>- engineered robust backend services that processed and analyzed large datasets, employing machine learning algorithms to personalize user experiences. <br>- Enhanced application security and performance by adopting best practices in code and database optimization, contributing to a 25% improvement in load times. <br>- Pioneered the implementation of continuous integration/continuous deployment (CI/CD) pipelines using AWS services, increasing deployment frequency by 40%.",
        },
        {
          from: new Date(2024, 1),
          title: "Senior Frontend Developer | EVmatch",
          color: dotColor,
          description:
            "- Developed, and implemented scalable and high-performance software solutions for mobile and web applications, ensuring seamless integration with EVMatch's existing infrastructure. <br>- Collaborating closely with cross-functional teams, including product management, UX/UI design, and QA to deliver top-notch, user-centric applications that improve the accessibility and efficiency of the EV charging process. <br>- Driving the adoption of best practices in software development, including code review, unit testing, and continuous integration/continuous deployment (CI/CD), resulting in improved code quality and faster release cycles. <br>- Actively participating in the ideation and development of new features and improvements, utilizing data-driven insights and user feedback to prioritize and deliver value to EVMatch's growing user base.",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 800;
  font-family: "Poppins";
  margin-bottom: 25px;
}
.timeline {
  max-width: unset;
}
</style>
