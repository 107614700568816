<template>
  <div class="shane_tm_section">
    <div class="shane_tm_skills">
      <div class="container">
        <div class="skills_inner">
          <div
            class="left"
            v-scroll-reveal="{ delay: 50, distance: '50px', duration: 1000 }"
          >
            <div class="shane_tm_title">
              <h3>I have high skills in web development</h3>
            </div>
            <div class="text">
              <p>
                I'm proficient in React.js, Next.js, Angular, Node, and Typescript for web development.
              </p>
            </div>
          </div>
          <!-- End .left -->

          <div class="right">
            <div class="tokyo_progress">
              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 50,
                  distance: '20px',
                  duration: 1000,
                }"
              >
                <span>
                  <span class="label">React.js/Redux/Next.js</span>
                  <span class="number">10 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div class="tokyo_progress">
              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 50,
                  distance: '20px',
                  duration: 1000,
                }"
              >
                <span>
                  <span class="label">Next.js</span>
                  <span class="number">7 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 100,
                  distance: '20px',
                  duration: 1200,
                }"
              >
                <span>
                  <span class="label">Angular/AngularJS</span>
                  <span class="number">10 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 150,
                  distance: '20px',
                  duration: 1300,
                }"
              >
                <span>
                  <span class="label">Node.js/Express.js</span>
                  <span class="number">9 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 150,
                  distance: '20px',
                  duration: 1300,
                }"
              >
                <span>
                  <span class="label">TypeScript</span>
                  <span class="number">8 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 150,
                  distance: '20px',
                  duration: 1300,
                }"
              >
                <span>
                  <span class="label">React Native</span>
                  <span class="number">4 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

              <div
                class="progress_inner"
                v-scroll-reveal="{
                  delay: 150,
                  distance: '20px',
                  duration: 1300,
                }"
              >
                <span>
                  <span class="label">Flutter</span>
                  <span class="number">2 years</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in" style="width:100%"></div>
                  </div>
                </div>
              </div>
              <!-- End .progress_inner -->

            </div>
          </div>
          <!-- End .right -->
        </div>
      </div>
      <!-- End .conainer -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
