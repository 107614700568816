<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portfolio</span>
            <h3>My Portfolio</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
    </div>

    <div class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <a :href="image.link" target="_blank">
              <div class="inner">
                <div class="entry shane_tm_portfolio_animation_wrap">
                  <img class="image" :src="image.thumb" alt="Portfolio" />
                </div>
                <!-- End .entry -->
                <div class="mobile_title">
                  <div class="title-wrapper">
                    <h3>{{ image.portfolioName }}</h3>
                    <span>Stacks: {{ image.portfolioType }}</span>
                  </div>
                  <div class="desc-wrapper">
                    {{ image.description }}
                  </div>
                </div>
              </div>
            </a>
          </li>
          <!-- End li -->
        </ul>
      </div>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: require("../../assets/img/portfolio/leadsigma.jpg"),
          thumb: require("../../assets/img/portfolio/leadsigma.jpg"),
          portfolioName: "LeadSigma",
          portfolioType: "React, Redux, Node.js, TypeScript, PostgreSQL, AWS",
          link: "https://www.leadsigma.com/",
          description:
            "I've worked on the website's admin dashboard and implemented various functionalities such as messaging, admin management, scheduling, reporting, and analytics. I've used React, Redux, Tailwind CSS, Bootstrap for the frontend, and Node.js and TypeScript for the backend.",
        },
        {
          src: require("../../assets/img/portfolio/morphtoken.jpg"),
          thumb: require("../../assets/img/portfolio/morphtoken.jpg"),
          portfolioName: "MorphToken",
          portfolioType: "React, Node",
          link: "https://morphtoken.com/",
          description:
            "Update the frontend and backend of crypto exchange site by using React, TypeScript,",
        },
        {
          src: require("../..//assets/img/portfolio/symposium.jpg"),
          thumb: require("../../assets/img/portfolio/symposium.jpg"),
          portfolioName: "Symposium",
          portfolioType: "Angular, TypeScript, Node, MongoDB, CircleCI, AWS",
          link: "https://symposium.foragerone.com/",
          description: "As a full-stack developer, I've worked on an existing site with a small team. I've updated the screens and animations with Angular 11. And I've implemented the new functionalities and RESTful APIs with Node.js. Code deployed with Github, and CI/CD on AWS.",
        },
        {
          src: require("../..//assets/img/portfolio/trxtraining.jpg"),
          thumb: require("../../assets/img/portfolio/trxtraining.jpg"),
          portfolioName: "TRX Training",
          portfolioType: "Vue, TypeScript, PHP, Laravel, Tailwind CSS, MySQL, GCP",
          link: "https://www.trxtraining.com/",
          description: "I've worked on fitness website and admin dashboard development. ",
        },
        {
          src: require("../..//assets/img/portfolio/tonikroos.jpg"),
          thumb: require("../..//assets/img/portfolio/tonikroos.jpg"),
          portfolioName: "Toni Kroos - Academy",
          portfolioType: "Flutter(iOS/Android)",
          link: "https://www.tonikroos-academy.com/",
          description: "I've updated the several versions of this app with Flutter.",
        },
        {
          src: require("../..//assets/img/portfolio/homee.jpg"),
          thumb: require("../..//assets/img/portfolio/homee.jpg"),
          portfolioName: "Homee",
          portfolioType: "React Native(iOS/Android)",
          link: "https://www.homee.com/",
          description: "I've developed the user and client apps for iOS and Android. I've used React Native, React.js, Swift, and Java.",
        },
      ],
      // vimeoItems: [
      //   {
      //     src: "https://vimeo.com/43338103",
      //     thumb: require("../../assets/img/portfolio/13.jpg"),
      //     portfolioName: "My Sound",
      //     portfolioType: "Vimeo",
      //     link: 'https://google.com'
      //   },
      //   {
      //     src: "https://vimeo.com/176916362",
      //     thumb: require("../../assets/img/portfolio/22.jpg"),
      //     portfolioName: "Old is Gold",
      //     portfolioType: "Vimeo",
      //     link: 'https://google.com'
      //   },
      // ],
      // youtubeItems: [
      //   {
      //     src: "https://youtu.be/YlBTaj0cXQY",
      //     thumb: require("../../assets/img/portfolio/15.jpg"),
      //     portfolioName: "Behance Design",
      //     portfolioType: "Youtube",
      //     link: 'https://google.com'
      //   },
      //   {
      //     src: "https://youtu.be/ZOoVOfieAF8",
      //     thumb: require("../../assets/img/portfolio/24.jpg"),
      //     portfolioName: "Themeforest Design",
      //     portfolioType: "Youtube",
      //     link: 'https://google.com'
      //   },
      // ],
      // mixItems: [
      //   {
      //     src: require("../../assets/img/portfolio/2.jpg"),
      //     thumb: require("../../assets/img/portfolio/2.jpg"),
      //   },
      //   {
      //     src: "https://youtu.be/ZOoVOfieAF8",
      //     thumb: require("../../assets/img/portfolio/14.jpg"),
      //   },
      // ],
      // galleryItems: [
      //   {
      //     src: require("../../assets/img/portfolio/11.jpg"),
      //     thumb: require("../../assets/img/portfolio/11.jpg"),
      //     portfolioName: "Beautiful Memory",
      //     portfolioType: "Gallery",
      //   },
      //   {
      //     src: require("../../assets/img/portfolio/14.jpg"),
      //     thumb: require("../../assets/img/portfolio/14.jpg"),
      //     portfolioName: "Childhood Memory",
      //     portfolioType: "Gallery",
      //   },
      //   {
      //     src: require("../../assets/img/portfolio/16.jpg"),
      //     thumb: require("../../assets/img/portfolio/16.jpg"),
      //     portfolioName: "University Lift",
      //     portfolioType: "Gallery",
      //   },
      // ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
