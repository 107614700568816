<template>
  <hooper :settings="hooperSettings">
    <slide v-for="(tesimonial, i) in testimonialContent" :key="i">
      <ul>
        <li class="item">
          <div class="texts">
            <p>
              {{ tesimonial.desc }}
            </p>
            <div class="author">
              <div class="image">
                <div
                  class="main"
                  :style="{ backgroundImage: 'url(' + tesimonial.imgSrc + ')' }"
                ></div>
              </div>
              <div class="short">
                <h3 class="author">
                  <span>{{ tesimonial.name }}</span>
                </h3>
                <h3 class="job">
                  <span>{{ tesimonial.designation }}</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </slide>
  </hooper>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
  },
  data() {
    return {
      testimonialContent: [
        {
          name: "Jone Moses",
          designation: "CTO",
          imgSrc: require("../../assets/img/about/t1.jpg"),
          desc: `"I would highly recommend Jason as he was extremely professional and incredibly helpful throughout the entire process of building our website. We have a staff of two so we don't have the resources to dedicate to designing a new site but he was amazing to work with."`,
        },
        {
          name: "Filip Jurgec",
          designation: "Co-Founder",
          imgSrc: require("../../assets/img/about/t2.jpg"),
          desc: `"The project was executed on time, on budget, and according to our vision. I couldn't ask for anything more!"`,
        },
        {
          name: "Johan Welch",
          designation: "COO/Co-founder",
          imgSrc: require("../../assets/img/about/t3.jpg"),
          desc: `"Jason has never let me down. I'm pleased with our partnership and they consistently develop reliable applications. He estimates a range of hours for each project and he completes it efficiently."`,
        },
      ],
      hooperSettings: {
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        mouseDrag: false,
        transition: 600,
        playSpeed: 3500,
        itemsToShow: 1,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
